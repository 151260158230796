var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "rounded card1 mx-4 my-2" }, [
    _c(
      "div",
      {
        staticClass: "header d-flex align-center justify-center py-1 rounded-t",
        class: _vm.theme === "light" ? "liner-bg" : "card2",
      },
      [
        _c(
          "svg",
          {
            attrs: {
              width: "24",
              height: "24",
              viewBox: "0 0 24 24",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg",
            },
          },
          [
            _c("path", {
              attrs: {
                d: "M2 4.937c0 .386.196.768.576 1.124.38.356.938.68 1.64.953.704.272 1.538.488 2.456.636.918.148 1.903.223 2.897.223.993 0 1.978-.075 2.896-.223.918-.148 1.752-.364 2.455-.636.703-.273 1.26-.597 1.641-.953.38-.356.576-.738.576-1.124 0-.385-.196-.767-.576-1.123-.38-.356-.938-.68-1.64-.953-.704-.272-1.538-.489-2.456-.636a18.417 18.417 0 0 0-2.896-.224c-.994 0-1.979.076-2.897.224-.918.147-1.752.364-2.455.636-.703.273-1.26.597-1.64.953C2.195 4.17 2 4.552 2 4.937z",
                fill: "#F6AD2A",
              },
            }),
            _c("path", {
              attrs: {
                d: "M17.098 4.635C16.708 3.155 13.486 2 9.568 2 5.389 2 2 3.314 2 4.936c0 1.622 3.389 2.936 7.569 2.936 3.047 0 5.673-.699 6.872-1.706.239-.497.457-1.009.657-1.531z",
                fill: "#F9BF34",
              },
            }),
            _c("path", {
              attrs: {
                d: "M9.569 2.001C5.389 2.001 2 3.316 2 4.937c0 1.121 1.62 2.095 4 2.59a20.131 20.131 0 0 0 4.715-5.492A18.951 18.951 0 0 0 9.569 2z",
                fill: "#FAC93A",
              },
            }),
            _c("path", {
              attrs: {
                d: "M9.569 11.553c3.858 0 6.736-1.162 7.568-2.413V6.146c0 1.622-3.389 2.936-7.568 2.936C5.389 9.082 2 7.768 2 6.146V9.14c.832 1.25 3.71 2.414 7.569 2.414z",
                fill: "#F6AD2A",
              },
            }),
            _c("path", {
              attrs: {
                d: "M2 6.146V9.14c.833 1.25 3.71 2.412 7.569 2.412 1.28 0 2.453-.129 3.48-.345a20.623 20.623 0 0 0 2.408-3.216c-1.388.666-3.509 1.092-5.888 1.092C5.389 9.085 2 7.77 2 6.146z",
                fill: "#F9BF34",
              },
            }),
            _c("path", {
              attrs: {
                d: "M2 6.146V9.14c.137.206.332.41.576.607a19.49 19.49 0 0 0 2.22-1.322C3.09 7.888 2 7.067 2 6.146z",
                fill: "#FAC93A",
              },
            }),
            _c("path", {
              attrs: {
                d: "M16.79 11.188c-2.88 0-5.212 2.421-5.212 5.406 0 2.986 2.334 5.406 5.211 5.406 2.878 0 5.21-2.421 5.21-5.406s-2.331-5.406-5.21-5.406zm-.068 8.084s-1.67-2.094-2.746-2.66c1.704-.979 2.72-2.763 2.72-2.763.277.667 1.698 2.18 2.591 2.712-1.655 1.15-2.565 2.71-2.565 2.71z",
                fill: "#F49D22",
              },
            }),
            _c("path", {
              attrs: {
                d: "M16.79 11.188c-2.88 0-5.212 2.421-5.212 5.406 0 1.351.48 2.587 1.27 3.535a19.994 19.994 0 0 0 2.846-2.04c-.542-.577-1.198-1.205-1.716-1.478 1.703-.979 2.72-2.763 2.72-2.763.17.411.775 1.142 1.423 1.768a20.575 20.575 0 0 0 2.109-3.081 5.086 5.086 0 0 0-3.44-1.347zm-6.16 5.218c-.349.018-.701.029-1.061.029-3.255 0-6-.756-7.569-1.945v1.607c0 1.621 3.389 2.936 7.569 2.936.524 0 1.035-.02 1.53-.06-.328-.952-.462-1.808-.47-2.567z",
                fill: "#F6AD2A",
              },
            }),
            _c("path", {
              attrs: {
                d: "M2 14.49v1.608c0 .405.212.791.594 1.142 1.219-.258 2.4-.632 3.532-1.112-1.723-.329-3.152-.9-4.126-1.637z",
                fill: "#F9BF34",
              },
            }),
            _c("path", {
              attrs: {
                d: "M11.869 12.63c-.73.086-1.5.132-2.3.132-3.255 0-6-.757-7.569-1.946v1.996c.833 1.251 3.71 2.413 7.569 2.413.398 0 .786-.014 1.163-.037a5.858 5.858 0 0 1 1.137-2.559z",
                fill: "#F6AD2A",
              },
            }),
            _c("path", {
              attrs: {
                d: "M9.569 12.762c-3.255 0-6-.757-7.569-1.946v1.996c.721 1.084 2.977 2.1 6.077 2.353a19.971 19.971 0 0 0 3.5-2.506c-.642.067-1.313.103-2.008.103z",
                fill: "#F9BF34",
              },
            }),
            _c("path", {
              attrs: {
                d: "M16.79 21.204c-2.425 0-4.397-2.045-4.397-4.56 0-2.516 1.972-4.562 4.396-4.562 2.425 0 4.397 2.046 4.397 4.561 0 2.516-1.972 4.561-4.396 4.561zm0-8.588c-2.14 0-3.882 1.807-3.882 4.027s1.742 4.027 3.882 4.027 3.881-1.807 3.881-4.027c-.001-2.22-1.741-4.027-3.881-4.027z",
                fill: "#F7F8F8",
              },
            }),
          ]
        ),
        _c(
          "div",
          {
            staticClass:
              "title-area ml-2 font-weight-bold subtitle-1 white--text",
          },
          [_vm._v(" List of Winners ")]
        ),
      ]
    ),
    _c("div", { staticClass: "content py-2 overflow-hidden" }, [
      _c(
        "div",
        { ref: "contentTable", staticClass: "content-table" },
        [
          _c(
            "v-container",
            _vm._l(_vm.listData, function (item, index) {
              return _c(
                "v-row",
                {
                  key: index,
                  staticClass:
                    "py-2 d-flex align-center justify-space-between px-5 caption ma-0 comment--text",
                },
                [
                  item.time
                    ? _c(
                        "v-col",
                        {
                          staticClass: "game-time d-flex pa-0",
                          attrs: {
                            cols: _vm.data.type === "marqueetra" ? 6 : 4,
                          },
                        },
                        [
                          _vm._v(" " + _vm._s(item.time) + " "),
                          _vm.data.type === "marqueetra"
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "action primary--text ml-7 font-weight-bold",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.actionType(item.trans_type)) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "v-col",
                    {
                      staticClass: "user-name pa-0",
                      attrs: { cols: _vm.data.type === "marqueetra" ? 3 : 4 },
                    },
                    [_vm._v(" " + _vm._s(item.account) + " ")]
                  ),
                  item.game_title
                    ? _c(
                        "v-col",
                        { staticClass: "game-name pa-0", attrs: { cols: "4" } },
                        [_vm._v(" " + _vm._s(item.game_title) + " ")]
                      )
                    : _vm._e(),
                  _c(
                    "v-col",
                    {
                      staticClass:
                        "amount secondary--text font-weight-bold text-right pa-0",
                      attrs: { cols: "3" },
                    },
                    [_vm._v(" " + _vm._s(item.amount) + " ")]
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }